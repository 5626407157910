import React from "react";
import TrailBlissHeroSection from "../sections/trailbliss/TrailBlissHeroSection";
import PageTransition from "../components/transition";
import TrailBlissProjectOverview from "../sections/trailbliss/TrailBlissProjectOverview";
import ContactMe from "../sections/footer";
import { Helmet } from "react-helmet";

export default function trailbliss() {
  return (
    <PageTransition>
      <Helmet>
        <title>Stephen Muchara - Trailbliss Case Study</title>
        {/* Add other meta tags for SEO and analytics here */}
      </Helmet>
      <div>
        <TrailBlissHeroSection />
        <div className="p-16 sm:p-4">
          <TrailBlissProjectOverview />
        </div>
        <ContactMe />
      </div>
    </PageTransition>
  );
}
