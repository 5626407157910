import React from "react";
import { motion } from "framer-motion";
import MyLogo from "../assets/images/myLogoBlack.png";
import { Link } from "react-router-dom";
import Menu from "./menu";
import { CustomBackgroundMenu } from "../utility/CustomBackgroundMenu";

const TopNavigationBar = () => {
  const backgroundColor = CustomBackgroundMenu(window.location.pathname);

  return (
    <motion.div
      className="flex justify-between items-center px-14 sm:px-4 py-8 sm:py-6 bg-transparent"
    >
      <Link to="/">
        <div className="flex items-center">
          <img src={MyLogo} alt="Logo" className="w-16 sm:w-8 sm:mr-2" />
          <div className="grid">
            <h1 className="font-bold text-lg sm:text-base">Stephen Muchara</h1>
            <h1 className="text-sm sm:text-xs">
              UX/UI Designer & Web Developer
            </h1>
          </div>
        </div>
      </Link>
      <Menu backgroundColor={backgroundColor} />
    </motion.div>
  );
};

export default TopNavigationBar;
