import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const MouseScroll = () => {
  const [scrolling, setScrolling] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      setScrolling((prevScrolling) => !prevScrolling);
    }, 1000); // Adjust the interval to control the speed of the scrolling animation

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  useEffect(() => {
    // Animate the dot's Y-axis position when scrolling state changes
    controls.start({
      y: scrolling ? 10 : 0, // You can adjust the Y-axis value and animation properties as needed
    });
  }, [scrolling, controls]);

  return (
    <div>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 sm:hidden">
        <div className="border-black border-2 rounded-full w-5 h-8 flex items-center justify-center">
          <motion.div
            className=" bg-black rounded-full w-1 h-2"
            animate={controls}
          ></motion.div>
        </div>
      </div>
    </div>
  );
};

export default MouseScroll;
