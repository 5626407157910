import React, { useEffect, useState } from "react";
import gsap from "gsap";
import LoadingSpinner from "../lottie/component/loadingSpinner";
import LoadingText from "../lottie/component/loadingText"; // Import your LoadingText component
import { CustomBackgroundMenu } from "../utility/CustomBackgroundMenu";

function PageTransition({ children }) {
  const [spinnerComplete, setSpinnerComplete] = useState(false);

  useEffect(() => {
    // Animation logic using GSAP for the transition effect after loading
    const overlayElement = document.querySelector(".overlay");
    const textLoadingElement = document.querySelector(".loading-container");
    // Disable scroll
    document.body.style.overflow = "hidden";

    if (overlayElement) {
      gsap.to(".bar", {
        delay: 3.5,
        height: 0,
        stagger: {
          amount: 0.5,
        },
        ease: "power4.inOut",
        duration: 1.5,
        onComplete: () => {
          // Remove the overlay and loading counter when the animation is complete
          overlayElement.style.display = "none";
          textLoadingElement.style.display = "none";

          // Enable scroll back
          document.body.style.overflow = "auto";
        },
      });
    }

    // Simulate loading by setting a timeout
    setTimeout(() => {
      console.log("Spinner complete");
      setSpinnerComplete(true);
    }, 3000);
  }, []);

  const customColor = CustomBackgroundMenu(window.location.pathname);

  return (
    <div className="w-full overflow-hidden">
      {/* Loading Spinner */}
      <div className="loading-container">
        {spinnerComplete ? null : <LoadingSpinner />}
        {spinnerComplete ? null : <LoadingText />}
      </div>

      <div className="overlay">
        {Array.from({ length: 5 }).map((_, index) => (
          <div
            className="bar"
            style={{ backgroundColor: customColor }}
            key={index}
          ></div>
        ))}
      </div>

      <div className="content">{spinnerComplete ? children : null}</div>
    </div>
  );
}

export default PageTransition;
