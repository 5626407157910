import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ProjectShowCase2 from "../assets/images/projectShowcase.png";
import MyLogo from "../assets/images/myLogoWhite.png";
import { Link } from "react-router-dom";

const About = () => {
  const [scrollY, setScrollY] = useState(0);
  const [imageScale, setImageScale] = useState(1);

  // Variants for button hover animation
  const buttonVariants = {
    hover: {
      scale: 1.1,
      boxShadow: "0px 0px 10px #ffffff",
      transition: {
        duration: 0.3,
      },
    },
  };

  // Variants for parallax effect on scroll
  const containerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.2,
        duration: 0.6,
      },
    },
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Adjust image scale based on scroll position
    const minScale = 1;
    const maxScale = 1.2;
    const scale =
      minScale + (maxScale - minScale) * (scrollY / window.innerHeight);

    setImageScale(scale);
  }, [scrollY]);

  return (
    <motion.div
      className="bg-custom-gray relative w-full overflow-hidden h-[80vh] sm:h-[50vh] tablet:h-[30vh] laptop:h-[70vh] text-white"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="absolute top-0 left-0 right-0 bottom-0">
        <div className="sm:hidden w-full huge:hidden">
          <motion.img
            src={ProjectShowCase2}
            alt="Collage of diverse UI designs created by Stephen Muchara, featuring various backgrounds and styles"
            style={{ scale: imageScale }}
          />
        </div>
        <img
          src={ProjectShowCase2}
          alt="Collage of diverse UI designs created by Stephen Muchara, featuring various backgrounds and styles"
          className="hidden sm:block huge:block w-full h-full object-cover"
        />
        <div className="absolute top-0 left-0 m-6 px-8 sm:px-0">
          <img
            src={MyLogo}
            alt="Stephen Muchara's personal logo"
            className="w-16 sm:hidden tablet:hidden"
          />
        </div>
        <div className="absolute bottom-0 left-0 m-6 px-8 sm:px-0">
          <div className="grid grid-cols-2 sm:grid-cols-1">
            <div>
              <p className="sm:text-xs tablet:text-xs">UX/UI Designer</p>
              <h1 className="text-3xl sm:text-sm tablet:text-base white text-left my-4 font-bold">
                Empowering Brands to Shine Bright in the Digital Spotlight
              </h1>
              <p className="text-justify sm:text-xs tablet:text-xs my-6">
                Welcome to my digital portfolio, where I demonstrate my skills
                in web development, UX/UI design, Android development, and
                WordPress. I have a track record of creating engaging digital
                experiences, contributing to brand identity enhancement through
                front-end development, collaboration, graphics, and branding.
                Let's explore my work and collaborate on projects that elevate
                brand visibility in the digital realm.
              </p>
              <Link to="/work">
                <motion.div
                  className="border-white border-2 rounded-sm px-4 py-2 max-w-max"
                  variants={buttonVariants}
                  whileHover="hover"
                >
                  Projects
                </motion.div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
