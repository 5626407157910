import React, { useState, useEffect, useCallback } from "react";
import { useAnimation } from "framer-motion"; // Import useAnimation
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"; // Import AnimatePresence
import { CustomBackgroundMenu } from "../utility/CustomBackgroundMenu";

export default function Menu() {
  const backgroundColor = CustomBackgroundMenu(window.location.pathname);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const controls = useAnimation(); // Create controls using useAnimation

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && isMenuOpen) {
        closeMenu();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isMenuOpen]);

  const navigationContainerVariant = {
    hidden: {
      x: isMenuOpen ? "100%" : "100%",
      opacity: 0,
      transition: {
        type: "spring",
        duration: 0.5,
        damping: 200,
        stiffness: 900,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    visible: {
      x: isMenuOpen ? "0%" : "100%",
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.5,
        damping: 200,
        stiffness: 1000,
        delay: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const listMenuVariant = {
    hidden: {
      x: 20,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  const menuItems = ["Home", "Work", "About", "Contact"];

  // Create a function to handle animation controls
  const animateMenu = useCallback(async () => {
    if (isMenuOpen) {
      await controls.start("visible");
    } else {
      await controls.start("hidden");
    }
  }, [isMenuOpen, controls]);

  useEffect(() => {
    animateMenu();
  }, [animateMenu]);

  return (
    <div>
      <div className="fixed top-0 right-0 m-8 sm:m-4 z-50">
        <div
          className="w-16 h-16 sm:w-12 sm:h-12 cursor-pointer rounded-full bg-white p-4 shadow-lg flex justify-center items-center"
          onClick={toggleMenu}
        >
          {/* Conditional rendering of menu icon or close button */}
          {isMenuOpen ? (
            <box-icon name="x" size="md"></box-icon> // Close button icon
          ) : (
            <box-icon name="menu" size="md"></box-icon> // Hamburger menu icon
          )}
        </div>
      </div>
      <AnimatePresence mode="wait">
        {isMenuOpen && (
          <motion.div
            key="menu"
            className={`z-10 w-[40%] sm:w-full fixed top-0 right-0 p-16 sm:p-10 h-screen text-white`}
            style={{ background: backgroundColor }}
            variants={navigationContainerVariant}
            initial="hidden"
            animate={isMenuOpen ? "visible" : "hidden"} // Use conditional animate prop
            exit="hidden"
          >
            <div className="mt-16">
              <p className="text-xs text-gray-300">CHECK THIS OUT</p>
            </div>
            {menuItems.map((item, index) => {
              return (
                <motion.li
                  key={index}
                  className="relative list-none my-10 text-4xl"
                  variants={listMenuVariant}
                >
                  {item === "Home" ? (
                    <Link to="/" className="text-white">
                      {item}
                    </Link>
                  ) : (
                    <Link
                      to={{ pathname: `/${item.toLowerCase()}` }}
                      className="text-white"
                    >
                      {item}
                    </Link>
                  )}
                </motion.li>
              );
            })}
            <div className="mt-16">
              <p className="text-xs text-gray-300">SOCIALS</p>
              <div className="grid grid-cols-3 my-4 sm:text-sm">
                <a
                  href="https://instagram.com/brand_virtuoso_designs?igshid=MzMyNGUyNmU2YQ=="
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/stephen-muchara-634487210/?originalSubdomain=ke"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
                <a
                  target="_blank"
                  href="https://github.com/smuchara"
                  rel="noreferrer"
                >
                  Github
                </a>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
