import React from "react";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

export default function OlolaiProjectCaseStudy() {
  // Set the Cloud configuration and URL configuration
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dfpasf355",
    },
    url: {
      secure: true,
    },
  });

  // cld.image returns a CloudinaryImage with the configuration set.
  const ololaiDemo = cld.image("case-study/ololai-tours/ehjehv3mijxx6enc6aev");
  return (
    <div>
      <h1 className="text-4xl sm:text-2xl font-bold my-16 sm:my-8">
        Project Overview
      </h1>
      <div className="grid grid-cols-5 my-6 sm:grid-cols-1">
        <div className=" col-span-3">
          <AdvancedImage
            cldImg={ololaiDemo}
            plugins={[placeholder("blur")]}
            className="object-cover mb-8"
            loading="lazy"
            alt="Stephen Muchara - Website Presentation of Ololai Tours in a Laptop"
          />
        </div>
        <div className="col-span-2">
          <p>
            In the realm of travel and exploration, Ololai Tours stands as a
            testament to the art of crafting extraordinary journeys. As the
            architect of unforgettable travel experiences, our passion resonates
            through the meticulous design of each expedition. Whether it's
            unearthing the hidden gems within Kenya's diverse landscapes or
            venturing into the global tapestry of wonders, Ololai Tours beckons
            you to embark on a journey beyond imagination. Our expert team is
            dedicated to curating not just trips, but transformative adventures
            that leave an indelible mark on your soul. From luxurious escapes to
            cultural immersions, we specialize in unlocking the world's most
            captivating destinations. Join us in unveiling the beauty, embracing
            the adventure, and creating memories that seamlessly weave into the
            tapestry of your life's adventures. Ololai Tours: Your gateway to a
            tour of a lifetime.
          </p>
          <div className="my-8">
            <a
              href="https://ololaitours.com/"
              rel="noreferrer"
              target="_blank"
              class="px-6 py-4 bg-black text-white rounded-lg w-60 transition-transform hover:scale-105 hover:bg-custom-orange"
            >
              Visit Site
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
