import React from "react";
import HeroSection from "../../assets/images/AssetFix.png";
import AdobeXD from "../../assets/images/adobeXD.png";
import AdobePhotoshop from "../../assets/images/Adobe_Photoshop_CC_icon.png";
import Figma from "../../assets/images/figma.png";

export default function AssetFixHeroSection() {
  return (
    <div className="sm:grid">
      <img
        src={HeroSection}
        alt={HeroSection}
        className="absolute sm:p-4 sm:rounded-3xl sm:relative top-0 left-0 -z-10 h-screen sm:h-auto object-cover w-screen"
      />
      <div className="px-16 sm:px-4 flex sm:grid">
        <div className="text-white sm:text-black">
          <div>
            <h1 className="text-4xl sm:text-xl font-bold">UX/UI Case Study</h1>
            <div className="grid grid-cols-4 sm:grid-cols-1">
              <p className="my-4">
                AssetsFix is a maintenance management Platform (CMMS) that helps
                manage assets, schedule maintenance and track work orders.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 sm:grid-cols-1 my-2">
            <div className="grid">
              <h1 className="text-4xl sm:text-xl font-bold">Tools Used</h1>
              <div className="grid grid-cols-8 py-4">
                <img src={AdobeXD} alt={AdobeXD} className="w-12 sm:w-6" />
                <img
                  src={AdobePhotoshop}
                  alt={AdobePhotoshop}
                  className="w-12 sm:w-6"
                />
                <img src={Figma} alt={Figma} className="w-12 sm:w-6" />
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-3xl font-bold">Project Duration</h1>
            <div className="grid grid-cols-2">
              <div className="grid grid-cols-2">
                <div>
                  <p className="my-4 text-4xl sm:text-xl">1</p>
                  <h1 className="my-2 text-2xl sm:text-xl">Month</h1>
                </div>
                <div>
                  <p className="my-4 text-4xl sm:text-xl">20</p>
                  <h1 className="my-2 text-2xl sm:text-xl">Screens</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex items-end">
          <a
            href="https://assetfix.co/"
            target="_blank"
            class="w-48 h-48 sm:rounded-lg sm:h-16 sm:my-6 rounded-full bg-black text-white p-5 flex items-center justify-center shadow-md"
            rel="noreferrer"
          >
            <box-icon
              name="right-arrow-alt"
              animation="tada"
              color="#ffffff"
            ></box-icon>
            Visit Website
          </a>
        </div>
      </div>
    </div>
  );
}
