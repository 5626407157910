import React, { useState, useEffect } from "react";

const ContactMe = () => {
  const [setIsCopiedEmail] = useState(false);
  const [setIsCopiedPhone] = useState(false);
  const [showCopiedPopupEmail, setShowCopiedPopupEmail] = useState(false);
  const [showCopiedPopupPhone, setShowCopiedPopupPhone] = useState(false);
  const email = "stephenfrancismuchara@gmail.com";
  const phoneNumber = "+254-741-732-905";
  const [localTime, setLocalTime] = useState("");

  // Function to format the current time as EAT
  const formatLocalTime = () => {
    const options = { timeZone: "Africa/Nairobi", hour12: true };
    const eatTime = new Date().toLocaleTimeString("en-US", options);
    return `${eatTime} EAT`;
  };

  // Update the local time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLocalTime(formatLocalTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleCopyEmailClick = () => {
    copyToClipboard(email, setIsCopiedEmail, setShowCopiedPopupEmail);
  };

  const handleCopyPhoneClick = () => {
    copyToClipboard(phoneNumber, setIsCopiedPhone, setShowCopiedPopupPhone);
  };

  const copyToClipboard = (text, setIsCopied, setShowCopiedPopup) => {
    // Create a temporary input element to copy the text
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Update the state to indicate that the text has been copied
    setIsCopied(true);

    // Show the copied popup message
    setShowCopiedPopup(true);

    // Reset the copied state and hide the popup after a short delay (e.g., 2 seconds)
    setTimeout(() => {
      setIsCopied(false);
      setShowCopiedPopup(false);
    }, 2000);
  };

  return (
    <div className="bg-dark-gray h-[60vh] p-16 tablet:p-12 tablet:h-[40vh] sm:p-4 text-white sm:h-[80vh] mt-16">
      <p className="text-5xl sm:text-2xl sm:my-8">Lets Work Together</p>
      <div className="flex sm:grid sm:mt-10">
        <div className="relative my-10 flex justify-center sm:my-3 border border-white rounded-full px-12 py-10 sm:px-4 sm:py-6 tablet:px-8">
          <box-icon
            type="solid"
            name="copy"
            color="white"
            onClick={handleCopyEmailClick}
          ></box-icon>
          {showCopiedPopupEmail && (
            <div className="text-black text-xs p-3 max-w-max bg-white absolute bottom-0 left-6 mt-16 sm:left-2 sm:mt-0 rounded-lg">
              Copied!
            </div>
          )}
          <p className="mx-4 sm:text-xs">{email}</p>
        </div>
        <div className="mx-8 sm:mx-0 sm:justify-start sm relative my-10 flex justify-center sm:my-3 border border-white rounded-full px-12 py-10 sm:px-8 sm:py-6 tablet:px-8">
          <box-icon
            type="solid"
            name="copy"
            color="white"
            onClick={handleCopyPhoneClick}
          ></box-icon>
          {showCopiedPopupPhone && (
            <div className="text-black text-xs p-3 max-w-max bg-white absolute bottom-0 left-6 mt-16 sm:left-2 sm:mt-0 rounded-lg">
              Copied!
            </div>
          )}
          <p className="mx-4 sm:text-xs">{phoneNumber}</p>
        </div>
      </div>
      <div className="flex justify-between sm:flex sm:flex-col-reverse">
        <div className="flex">
          <div className="grid">
            <p className="text-xs my-2">Version</p>
            <p className="text-sm">@2023</p>
          </div>
          <div className="grid mx-8">
            <p className="text-xs my-2">LOCAL TIME</p>
            <p className="text-sm">{localTime}</p>
          </div>
        </div>
        <div className="grid sm:my-6">
          <p className="text-xs my-2">Socials</p>
          <div className="grid grid-cols-3 gap-4 my-4">
            <a
              className="text-sm"
              target="_blank"
              href="https://instagram.com/brand_virtuoso_designs?igshid=MzMyNGUyNmU2YQ=="
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/stephen-muchara-634487210/?originalSubdomain=ke"
              rel="noreferrer"
              className="text-sm"
            >
              LinkedIn
            </a>
            <a
              target="_blank"
              href="https://github.com/smuchara"
              rel="noreferrer"
              className="text-sm"
            >
              Github
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
