import React, { useState, useEffect } from "react";
import TopNavigationBar from "../components/topNavBar";
import Content from "../sections/Content";

export default function HeroSection() {
  const [wavePosition, setWavePosition] = useState({ x: 0, y: 0 });
  const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });
  const [circleSize] = useState(50);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setWavePosition({ x: clientX, y: clientY });
  };

  useEffect(() => {
    const updateCirclePosition = () => {
      // Adjust the lerp factor for smoother or faster animation
      const lerpFactor = 0.5;

      setCirclePosition((prev) => ({
        x: prev.x + (wavePosition.x - prev.x) * lerpFactor,
        y: prev.y + (wavePosition.y - prev.y) * lerpFactor,
      }));

      requestAnimationFrame(updateCirclePosition);
    };

    updateCirclePosition();

    // Add an event listener to the window to track mouse movements
    window.addEventListener("mousemove", handleMouseMove);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [wavePosition]);

  const circleStyle = {
    position: "absolute",
    width: `${circleSize}px`,
    height: `${circleSize}px`,
    backgroundColor: "white",
    borderRadius: "50%",
    top: circlePosition.y - circleSize / 2,
    left: circlePosition.x - circleSize / 2,
    mixBlendMode: "difference",
    pointerEvents: "none", // Add this line to allow events to pass through the circle
  };

  return (
    <div className="relative bg-white">
      <TopNavigationBar />
      <Content />
      <div style={circleStyle} className="sm:hidden"></div>
    </div>
  );
}
