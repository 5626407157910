/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import MyLogo from "../../assets/images/myLogoWhite.png";

export default function TrailBlissProjectOverview() {
  const categories = [
    {
      items: [
        "Discover",
        "Surveys",
        "In-Depth Interviews",
        "UX Persona",
        "Empathy Map",
        "User-Story Map",
        "CJM",
      ],
    },
    {
      items: [
        "Define",
        "Competitor Analysis",
        "Information Architecture",
        "User Flow",
      ],
    },
    {
      items: ["Develop", "High Fidelity Wireframe", "UI Kit"],
    },
    {
      items: ["Deliver", "Prototyping"],
    },
  ];

  // Set the Cloud configuration and URL configuration
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dfpasf355",
    },
    url: {
      secure: true,
    },
  });

  // cld.image returns a CloudinaryImage with the configuration set.
  const interviewImage = cld.image("case-study/trailbliss/lycfh6hvtgek0uxzlpfc");
  const mockupImage = cld.image("case-study/trailbliss/whwzfwdcyiwzbvq5kh55");
  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
        <div className="rounded-xl p-8 shadow-xl grid bg-light-brown">
          <h1 className="text-2xl font-bold my-8">Problem</h1>
          <p>
            TrailBliss Travel Agency faced a critical challenge in its absence
            from the digital landscape, lacking a website that has become a
            hallmark of professionalism in the modern era. This absence not only
            hindered the agency's visibility but also conveyed an outdated
            image, potentially deterring clients seeking seamless online
            experiences. In an era where digital presence is synonymous with
            credibility, the absence of a website left TrailBliss at a
            competitive disadvantage, emphasizing the urgent need for a
            comprehensive digital transformation to elevate their professional
            image and enhance client engagement.
          </p>
        </div>
        <div className="rounded-xl p-8 shadow-xl grid bg-custom-brown">
          <h1 className="text-2xl font-bold my-8">Solution</h1>
          <p>
            The resolution to TrailBliss Travel Agency's digital challenge
            involved the creation of a sophisticated website, strategically
            designed to establish a powerful online presence. By meticulously
            crafting an intuitive and visually appealing platform, we addressed
            the agency's lack of visibility in the digital realm. The website
            not only enhances professionalism but also serves as a central hub
            for clients to explore services, initiate visa applications, and
            access personalized travel resources. This strategic move not only
            rectifies the absence of a digital footprint but positions
            TrailBliss at the forefront of the travel industry, embracing the
            contemporary expectations of a tech-savvy clientele.
          </p>
        </div>
      </div>

      <div className="relative h-[1/2] rounded-xl overflow-hidden shadow-md my-16 sm:my-8">
        <div className="absolute top-0 left-0 right-0 m-6 px-8 sm:px-0 flex justify-between items-center">
          <img src={MyLogo} alt="Stephen Muchara's Brand Logo" className="w-16 sm:w-8" />
          <p className="rounded-full border border-white px-3 py-2 text-white sm:text-sm">
            UX/UI Design
          </p>
          <p className="rounded-full border border-white px-3 py-2 text-white sm:text-sm">
            2023
          </p>
        </div>
        <AdvancedImage
            cldImg={mockupImage}
            plugins={[placeholder("blur")]}
            className="object-cover w-full h-full"
            alt="Ololai Tours Logo - Designed by Stephen Muchara"
          />
      </div>

      <div className="rounded-xl p-8 shadow-xl grid bg-light-brown mb-16">
        <h1 className="my-8 text-4xl sm:text-2xl font-bold">Design Process</h1>
        <div className="grid grid-cols-4 sm:grid-cols-1 gap-6">
          {categories.map((category, index) => (
            <div key={index} className="bg-custom-brown p-6 rounded-2xl">
              <div className="mb-8 text-xl text-center">
                {category.items[0]}
              </div>
              {category.items.slice(1).map((item, i) => (
                <div key={i} className="w-full flex justify-center text-white">
                  <div className="max-w-max border border-white px-4 py-2 rounded-full text-center my-2">
                    {item}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-1  gap-8">
        <div className="flex items-center justify-center h-96 rounded-xl overflow-hidden shadow-md">
          <AdvancedImage
            cldImg={interviewImage}
            plugins={[placeholder("blur")]}
            className="object-cover w-full h-full"
            alt="Ololai Tours Logo - Designed by Stephen Muchara"
          />
        </div>
        <div className="grid p-8 h-96 rounded-xl overflow-hidden shadow-md bg-custom-brown">
          <h1 className="text-2xl">In-depth Interview</h1>
          <p className="flex items-end">
            I conducted in-depth interviews, delving into the perspectives of
            key stakeholders and potential users, to gain valuable insights into
            their preferences, expectations, and pain points regarding travel
            experiences and digital platforms
          </p>
        </div>
        <div className="bg-light-brown flex items-center justify-center p-8 rounded-xl overflow-hidden shadow-md">
          <p>
            "Can you share a memorable travel experience and highlight aspects
            that made it enjoyable or challenging? What specific features or
            services do you typically look for when planning a trip?"
          </p>
        </div>
        <div className="bg-light-brown flex items-center justify-center p-8 rounded-xl overflow-hidden shadow-md">
          <p>
            "In your travel planning process, how do you currently interact with
            online platforms? What features or functionalities do you find most
            useful or lacking in existing travel websites or applications?"
          </p>
        </div>
        <div className="bg-light-brown flex items-center justify-center p-8 rounded-xl overflow-hidden shadow-md">
          <p>
            "Considering the services provided by travel agencies, what are your
            expectations when engaging with one? What would make you choose one
            agency over another, especially in the digital context?"
          </p>
        </div>
        <div className="bg-light-brown flex items-center justify-center p-8 rounded-xl overflow-hidden shadow-md">
          <p>
            "If you've ever applied for a visa, can you describe your
            experience? What challenges did you face, and what improvements or
            support would you appreciate in the visa application process through
            a travel agency's website?"
          </p>
        </div>
        <div className="bg-light-brown flex items-center justify-center p-8 rounded-xl overflow-hidden shadow-md">
          <p>
            "Thinking about an ideal travel platform, what features or design
            elements would make your user experience seamless and enjoyable? How
            important is a visually appealing and intuitive interface to you
            when using digital platforms for travel-related activities?"
          </p>
        </div>
      </div>
    </div>
  );
}
