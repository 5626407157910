import React, { useState, useEffect } from "react";
import { motion} from "framer-motion";

const MessageDisplay = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true);
  const messageClass = type === "success" ? "text-green-500" : "text-red-500";

  useEffect(() => {
    // Simulate a timeout to hide the message after 3 seconds (adjust the time as needed)
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    // Clear the timeout when the component unmounts or when isVisible becomes false
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{
            type:"spring",
            duration:0.5,
            damping:500,
            stiffness:20
          }}
          className={`text-center mt-4 ${messageClass} p-6 shadow-xl rounded-lg max-w-max`}
        >
          <p>{message}</p>
        </motion.div>
      )}
    </>
  );
};

export default MessageDisplay;
