import React from "react";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

export default function OlolaiProjectDesign() {
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dfpasf355",
    },
    url: {
      secure: true,
    },
  });

  // cld.image returns a CloudinaryImage with the configuration set.
  const myDesignProcess = cld.image(
    "case-study/ololai-tours/cr3spuu16spmdylgk3mi"
  );
  return (
    <div
      className="h-screen w-screen bg-custom-orange p-16 sm:p-4 sm:bg-none sm:h-[50vh] bg-hero-pattern bg-no-repeat"
      style={{
        backgroundPosition: "right",
        backgroundSize: "contain",
      }}
    >
      <h1 className="text-4xl sm:text-2xl font-bold pb-16 sm:pb-4 text-white">
        Project Design
      </h1>
      <AdvancedImage
        cldImg={myDesignProcess}
        plugins={[placeholder("blur")]}
        className="h-[90%] sm:h-4/5"
        alt="UX/UI Design Process - Stephen Muchara's Case Study"
      />
    </div>
  );
}
