import React, { useEffect } from "react";
import TopNavigationBar from "../components/topNavBar";
import FemFlyVideo from "../assets/videos/Femfly.mp4";
import ContactMe from "../sections/footer";
import PageTransition from "../components/transition";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { Helmet } from "react-helmet";

const FemFly = () => {
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Set the Cloud configuration and URL configuration
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dfpasf355",
    },
    url: {
      secure: true,
    },
  });

  // cld.image returns a CloudinaryImage with the configuration set.
  const laptop = cld.image("case-study/femfly/crkrooapdexajmnslgyi");
  const caseStudyMobile = cld.image("case-study/femfly/axvqqn6e4ydmyx4upcbv");
  const caseStudy2 = cld.image("case-study/femfly/v5gykllaaaaunc6itknf");
  const summary = cld.image("case-study/femfly/hprln8x59is7riiclwub");
  const sneakPeek = cld.image("case-study/femfly/qcihk7hafuh25rg4nepk");

  return (
    <PageTransition>
      <Helmet>
        <title>Stephen Muchara - FemFly Case Study</title>
        {/* Add other meta tags for SEO and analytics here */}
      </Helmet>
      <TopNavigationBar />
      {/* First Section */}
      <div className="w-full my-8 sm:my-4">
        <div className="m-auto w-full sm:px-4">
          <div className="flex justify-evenly sm:grid sm:justify-normal">
            <div>
              <p className="text-sm text-gray-300 my-4 sm:text-xs">ROLE</p>
              <hr></hr>
              <p className="my-4 sm:text-xs">Web Developer & Designer</p>
            </div>
            <div>
              <p className="text-sm text-gray-300 my-4 sm:text-xs">AGENCY</p>
              <hr></hr>
              <p className="my-4 sm:text-xs">Femfly Travelling Agency</p>
            </div>
            <div>
              <p className="text-sm text-gray-300 my-4 sm:text-xs">DURATION</p>
              <hr></hr>
              <p className="my-4 sm:text-xs">October 2023 - November 2023</p>
            </div>
          </div>
        </div>
      </div>
      {/* Second Section */}
      <div className="flex justify-center items-center w-full sm:my-16">
        <div className="relative">
          <div className="w-[75%] mx-auto sm:w-[85%]">
            <AdvancedImage
              cldImg={laptop}
              plugins={[placeholder("blur")]}
              className="w-full"
              loading="lazy"
              alt="Laptop with Display"
            />
          </div>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-[60%] sm:w-[70%] h-[89%] absolute left-0 top-0 bottom-0 right-0 mx-auto -z-10" // Center the video
          >
            <source src={FemFlyVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      {/* Third Section */}
      <div className="py-14 px-16 grid grid-cols-4 sm:grid-cols-1 sm:px-4 sm:py-0">
        <div className="col-span-2">
          <h1 className="text-3xl font-bold">Project Overview</h1>
          <p className="my-4">Femfly Travelling Agency</p>
          <p className="sm:text-sm">
            Femfly Travel Agency, a burgeoning player in the travel and
            immigration industry, sought to establish a robust online presence.
            With a commitment to helping individuals explore Canada, settle, and
            work, Femfly faced the challenge of introducing itself to a broader
            audience while ensuring transparency and credibility.
          </p>
        </div>
        <div className="sm:my-8">
          <h1 className="font-bold">ROLE</h1>
          <p className="my-4 sm:text-sm">Web Developer/ Designer</p>
        </div>
        <div className="sm:my-8">
          <h1 className="font-bold">RESPONSIBILITIES</h1>
          <div className="my-4 sm:text-sm">
            <p>UX/UI</p>
            <p>Prototyping</p>
            <p>Product Strategy</p>
            <p>System Design</p>
          </div>
        </div>
      </div>
      {/* Forth Section */}
      <div className="bg-gray-50 p-8 rounded-md shadow-lg mx-16 m-auto sm:mx-4">
        <h1 className="font-bold my-4">Problem</h1>
        <p className="sm:text-sm">
          Femfly Travel Agency had no prior online presence, requiring the
          development of a new digital platform. Building trust and credibility
          in the industry while ensuring user-friendly design and transparent
          visa information were key challenges.
        </p>
      </div>
      {/* Fifth Section */}
      <div className="my-8">
        <div className="relative">
          <AdvancedImage
            cldImg={caseStudyMobile}
            plugins={[placeholder("blur")]}
            className="w-full"
            loading="lazy"
            alt="Stephen Muchara - Mobile Prototype Mockup of Website Design"
          />
        </div>
      </div>
      {/* Sixth Section */}
      <div className="my-8">
        <div className="bg-gray-50 p-8 rounded-md shadow-lg mx-16 m-auto sm:mx-4">
          <h1 className="font-bold my-4">My Vision</h1>
          <p className="sm:text-sm">
            In this project, I envision transforming Femfly Travel Agency's
            digital presence into a trusted and inspiring platform. It will
            provide comprehensive information, guiding individuals on their
            journey to explore, settle, and work in Canada. The website's design
            will prioritize accessibility and user-friendliness, reflecting a
            commitment to inclusivity and professionalism. Transparency will be
            central, ensuring that every detail about visa processes,
            requirements, and settlement options is readily available.
            Ultimately, the project aims to empower individuals with the
            knowledge and resources needed to turn their dreams into reality,
            establishing Femfly as a beacon of trust and guidance in the travel
            and immigration industry.
          </p>
        </div>
      </div>
      <div>
        <AdvancedImage
          cldImg={caseStudy2}
          plugins={[placeholder("blur")]}
          className="w-full"
          loading="lazy"
          alt="Stephen Muchara - Website Presentation on Multiple Desktop Screens"
        />

        <img src={caseStudyMobile} alt="" className="" />
      </div>
      <div className="flex justify-center my-16">
        <AdvancedImage
          cldImg={summary}
          plugins={[placeholder("blur")]}
          className="w-1/3 sm:w-4/5"
          loading="lazy" // Add this line to enable lazy loading
          alt="Stephen Muchara's - Design Process"
        />

        <img src={caseStudyMobile} alt="" className="" />
      </div>
      <div className="m-16 sm:m-4">
        <h1 className="text-3xl font-bold">Sneek Peak</h1>
        <p>High-Level Prototyping</p>
        <p className="my-4">
          In the image, we see a high-level prototype of the Femfly Travel
          Agency website. The prototype represents the initial blueprint for the
          website's design and functionality. It features a clean and intuitive
          user interface with a well-organized layout.{" "}
        </p>
        <AdvancedImage
          cldImg={sneakPeek}
          plugins={[placeholder("blur")]}
          className="w-full"
          loading="lazy"
          alt="Stephen Muchara - Sneak Peek of Figma Design"
        />
      </div>
      <ContactMe />
    </PageTransition>
  );
};

export default FemFly;
