import React from "react";

export default function FilterButton({ buttonName, filterType, onClick, isActive, count }) {
  return (
    <>
      <div
        className={`cursor-pointer hover:duration-100 hover:shadow-sm rounded-full px-8 py-4 border ${isActive ? "bg-gray-300" : "border-black"} max-w-max mx-4 my-6 sm:mx-2 sm:my-2 sm:text-xs sm:px-4 sm:py-3`}
        onClick={() => onClick(filterType)}
      >
        <span>{buttonName}<sup className="m-2">{count}</sup></span>
      </div>
    </>
  );
}
