import React from 'react'
import { Helmet } from "react-helmet";
import AssetFixHeroSection from '../sections/assetfix/AssetFixHeroSection';
import TopNavigationBar from "../components/topNavBar"
import PageTransition from '../components/transition';

export default function AssetFix() {
  return (
    <PageTransition>
       <Helmet>
        <title>Stephen Muchara - AssetFix Case Study</title>
        {/* Add other meta tags for SEO and analytics here */}
      </Helmet>
      <TopNavigationBar/>
      <AssetFixHeroSection/>
    </PageTransition>
  )
}
