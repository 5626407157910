import React from "react";

const categories = [
  {
    items: [
      "Discover",
      "Surveys",
      "In-Depth Interviews",
      "UX Persona",
      "Empathy Map",
      "User-Story Map",
      "CJM",
    ],
  },
  {
    items: [
      "Define",
      "Competitor Analysis",
      "Information Architecture",
      "User Flow",
    ],
  },
  {
    items: ["Develop", "High Fidelity Wireframe", "UI Kit"],
  },
  {
    items: ["Deliver", "Prototyping"],
  },
];

const problemStatements = [
  {
    number: "01",
    title: "Lack of Visibility",
    description:
      "Lack of online presence makes the client invisible in the digital landscape, hindering potential customer discovery and limiting outreach.",
  },
  {
    number: "02",
    title: "Limited Market Reach",
    description:
      "Client's reliance on traditional methods restricts market reach, hindering connection with a diverse audience and competitiveness in the digital era, where consumers turn to the internet for information and services.",
  },
  {
    number: "03",
    title: "Missed Customer Engagement",
    description:
      "Lack of online presence not only hampers customer attraction but also limits meaningful engagement. Online platforms are essential for interactive communication, feedback, and relationship-building, crucial for brand loyalty and customer satisfaction.",
  },
  {
    number: "04",
    title: "Competitive Disadvantage",
    description:
      "Client's lack of online presence presents a significant competitive disadvantage in an industry where competitors leverage digital platforms. The absence may be perceived as outdated, impacting market standing and credibility compared to more accessible and visible online competitors.",
  },
];

const problemSolutions = [
    {
      number: "01",
      title: "Comprehensive SEO Strategy",
      solutions: `
        To address the lack of visibility, a comprehensive SEO strategy is crucial. This involves conducting thorough keyword research to understand what potential customers are searching for. Additionally, optimizing website content, meta tags, and headings with relevant keywords is essential. Regularly updating and adding fresh, high-quality content is also imperative to improve search engine rankings and overall visibility in the digital landscape.
      `,
    },
    {
      number: "02",
      title: "Responsive and Fast-Loading Website",
      solutions: `
        Overcoming the challenge of a limited market reach requires investment in a responsive and fast-loading website. This ensures accessibility for a diverse audience, including those who rely on the internet for information and services. Optimization of images and efficient coding practices contribute to faster page loading, and considering a Content Delivery Network (CDN) further enhances website speed globally.
      `,
    },
    {
      number: "03",
      title: "Social Media Presence",
      solutions: `
        To address the issue of missed customer engagement, establishing a strong social media presence is imperative. This involves creating and regularly updating profiles on major platforms, sharing engaging content such as industry updates and customer testimonials. Additionally, utilizing social media advertising can significantly enhance outreach and engagement with a wider audience, fostering meaningful connections.
      `,
    },
    {
      number: "04",
      title: "Content Marketing",
      solutions: `
        Developing a content marketing strategy is essential for overcoming the competitive disadvantage. This includes creating a content calendar with a mix of blog posts, videos, and infographics. Sharing valuable and informative content that resonates with the target audience is crucial. Additionally, guest posting on relevant industry websites can further enhance visibility and credibility.
      `,
    },
    {
      number: "05",
      title: "Online Advertising",
      solutions: `
        To increase online visibility, investing in targeted online advertising campaigns is key. This may involve utilizing platforms such as Google Ads or social media ads. Implementing retargeting strategies helps keep the brand in front of potential customers, and continuous monitoring and adjustment of ad campaigns based on performance analytics ensure optimal results.
      `,
    },
    {
      number: "06",
      title: "Email Marketing",
      solutions: `
        Building and maintaining an email subscriber list is crucial for direct communication with customers. Sending regular newsletters with updates, promotions, and exclusive offers helps in staying connected. Personalizing emails based on customer preferences and behavior further enhances engagement and loyalty.
      `,
    },
    {
      number: "07",
      title: "User-friendly Website Design",
      solutions: `
        Ensuring a clean and intuitive website layout is essential for a positive user experience. Implementing clear calls-to-action (CTAs) guides visitors towards desired actions, enhancing overall user satisfaction. Regular testing and optimization of the website design based on user feedback and analytics contribute to continuous improvement.
      `,
    },
    {
      number: "08",
      title: "Online Reviews and Testimonials",
      solutions: `
        To build credibility and address the competitive disadvantage, encouraging satisfied customers to leave positive reviews on review platforms is crucial. Displaying customer testimonials prominently on the website adds authenticity. Addressing and resolving negative feedback promptly showcases excellent customer service and reinforces a positive brand image.
      `,
    },
  ];
  
  
export default function OlolaiDesignProcess() {
  return (
    <div>
      <h1 className="my-12 text-4xl sm:text-2xl font-bold">Design Process</h1>
      <div className="grid grid-cols-4 sm:grid-cols-1 pr-4 gap-8">
        {categories.map((category, index) => (
          <div
            key={index}
            className={
              index < categories.length - 1 ? "border-r border-dashed" : ""
            }
          >
            <div className="grid">
              {category.items.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className={`my-3 rounded-xl ${
                    itemIndex === 0
                      ? "bg-custom-orange text-white w-1/2"
                      : "bg-gray-300 text-gray-500"
                  } px-4 py-2 max-w-max`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <h1 className="my-12 text-4xl font-bold">Problem Statement</h1>
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-4">
        {problemStatements.map((problem, index) => (
          <div
            key={index}
            className="shadow-xl rounded-2xl rounded-tl-none p-4"
          >
            <p className="text-[#F78F2E] text-2xl font-semibold">
              {problem.number}
            </p>
            <p className="font-bold my-4">{problem.title}</p>
            <p className="text-sm">{problem.description}</p>
          </div>
        ))}
      </div>
      <h1 className="my-12 text-4xl font-bold">Problem Solutions</h1>
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-4">
        {problemSolutions.map((solution, index) => (
          <div
            key={index}
            className="shadow-xl rounded-2xl rounded-tl-none p-4"
          >
            <p className="text-[#F78F2E] text-2xl font-semibold">
              {solution.number}
            </p>
            <p className="font-bold my-4">{solution.title}</p>
            <p className="text-sm">{solution.solutions}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
