import React, { useState, useEffect, useRef } from "react";
import TopNavigationBar from "../components/topNavBar";
import PageTransition from "../components/transition";
import emailjs, { send } from "@emailjs/browser";
import MessageDisplay from "../components/MessageDisplay";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [localTime, setLocalTime] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // New state variable

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isFormSubmitted) {
      emailjs
        .sendForm(
          "service_ryf60u8",
          "template_92qm5re",
          form.current,
          "pIzacbPf2Tf9zwJ9s"
        )
        .then(
          (result) => {
            setSuccessMessage("Email sent successfully!");
            setIsFormSubmitted(true); // Disable form submission
            console.log(result.text);
          },
          (error) => {
            setErrorMessage(
              "An error occurred while sending the email. Please try again later."
            );
            console.log(error.text);
          }
        );
    }
  };

  // Function to format the current time as EAT
  const formatLocalTime = () => {
    const options = { timeZone: "Africa/Nairobi", hour12: true };
    const eatTime = new Date().toLocaleTimeString("en-US", options);
    return `${eatTime} EAT`;
  };

  // Update the local time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLocalTime(formatLocalTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <PageTransition>
      <Helmet>
        <title>Stephen Muchara - Contact Page</title>
      </Helmet>
      <TopNavigationBar />
      <div className="mx-24 my-12 p-8 sm:mx-0 sm:my-0 tablet:mx-4 tablet:my-4">
        <h1 className="text-5xl sm:text-xl font-bold">Hello There !!</h1>
        <h1 className="text-5xl sm:text-xl font-bold">
          Can't Wait To Work With You
        </h1>
        <div className="grid grid-cols-5 sm:grid-cols-1 my-16">
          <div className="col-span-3">
            <form ref={form} onSubmit={sendEmail}>
              <div className="grid mb-16 sm:mb-0">
                <label htmlFor="name">What's Your Name ?</label>
                <input
                  type="text"
                  name="from_name"
                  class="py-4 bg-white border border-white placeholder-gray-500 focus:outline-none focus:border-white focus:ring-white block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="John Doe"
                  required
                />
                <hr />
              </div>
              <div className="grid my-12">
                <label htmlFor="email">What's Your Email ?</label>
                <input
                  type="email"
                  name="email"
                  class="py-4 bg-white border border-white placeholder-gray-500 focus:outline-none focus:border-white focus:ring-white block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="john@abc.com"
                  required
                />
                <hr />
              </div>
              <div className="grid my-16">
                <label htmlFor="organization">What's Your Organisation ?</label>
                <input
                  type="text"
                  name="organisation"
                  class="py-4 bg-white border border-white placeholder-gray-500 focus:outline-none focus:border-white focus:ring-white block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Blue Star"
                  required
                />
                <hr />
              </div>
              <div className="grid my-16">
                <label htmlFor="organization">
                  What's Services Are You Looking For ?
                </label>
                <input
                  type="text"
                  name="services"
                  class="py-4 bg-white border border-white placeholder-gray-500 focus:outline-none focus:border-white focus:ring-white block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Web Application Design, Website Development,"
                  required
                />
                <hr />
              </div>
              <div className="grid my-16 sm:my-8">
                <label htmlFor="message">Write Me A Message</label>
                <textarea
                  rows={3}
                  type="text"
                  name="message"
                  class="py-4 bg-white border border-white placeholder-gray-500 focus:outline-none focus:border-white focus:ring-white block w-full rounded-md sm:text-sm focus:ring-1"
                  placeholder="Hello Stephen, I would love .."
                  required
                />
                <hr />
              </div>
              <button
                type="submit"
                value={send}
                className={`bg-black w-64 px-4 py-6 rounded-lg shadow-md text-white ${
                  isFormSubmitted ? "disabled-button" : ""
                }`}
                disabled={isFormSubmitted}
              >
                Send
              </button>
              {successMessage && (
                <MessageDisplay message={successMessage} type="success" />
              )}
              {errorMessage && (
                <MessageDisplay message={errorMessage} type="error" />
              )}
            </form>
          </div>
          <div className="col-span-2 mx-8 sm:mx-0 sm:my-8 tablet:mx-4 tablet:my-4">
            <div>
              <h1 className="mb-2 text-xs text-gray-500">CONTACT DETAILS</h1>
              <p className="my-2">stephenfrancismuchara@gmail.com</p>
              <p className="my-2">+254-741-732-905</p>
            </div>
            <div className="my-8">
              <h1 className="mb-2 text-xs text-gray-500">LOCATION</h1>
              <p className="my-2">Nairobi</p>
              <p className="my-2">Kenya</p>
            </div>
            <div className="my-8">
              <h1 className="my-2 text-xs text-gray-500">SOCIALS</h1>
              <p className="my-2">LinkedIn</p>
              <p className="my-2">Github</p>
              <p className="my-2">Instagram</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex px-16 py-4 sm:px-0 sm:py-8 tablet:mx-4 tablet:my-4">
        <div className="grid mx-8">
          <p className="text-xs my-2"> VERSION </p>
          <p className="text-sm">2022 © Edition</p>
        </div>
        <div className="grid mx-8">
          <p className="text-xs my-2">LOCAL TIME</p>
          <p className="text-sm">{localTime}</p>
        </div>
      </div>
    </PageTransition>
  );
}
