export const CustomBackgroundMenu = (pathname) => {
  console.log("Current Pathname:", pathname);

  if (pathname === "/caseStudy/femfly" || pathname === "/work/caseStudy/femfly") {
    return "#00ADEF";
  } else if (pathname === "/caseStudy/ololaitours" || pathname === "/work/caseStudy/ololaitours") {
    return "orange";
  } else if (pathname === "/caseStudy/trailbliss" || pathname === "/work/caseStudy/trailbliss") {
    return "#CA9F41";
  } else if (pathname === "/caseStudy/assetfix" || pathname === "/work/caseStudy/assetfix") {
    return "#de9d00";
  } else if (pathname === "/") {
    return "#0E0E0E";
  } else {
    return "#0E0E0E"; // Default color if none of the conditions match
  }
};
