import React from "react";
import TopNavigationBar from "../../components/topNavBar";
import MouseScroll from "../../components/mouse";

export default function TrailBlissHeroSection() {
  return (
    <div className="mx-16 sm:mx-0 my-6 sm:my-0 h-screen bg-trailbliss bg-no-repeat bg-center bg-cover relative rounded-xl p-8 sm:p-0 shadow-xl sm:shadow-sm grid bg-custom-brown">
      <TopNavigationBar />
      <div className="p-16 sm:p-4 grid grid-cols-3">
        <div>
          <h1 className="font-bold text-3xl my-4">
            TrailBliss Traveliing Agency
          </h1>
          <p className="my-3">Discover</p>
          <p className="my-3">Arrange</p>
          <p className="my-3">Navigate</p>
          <p className="my-3 mb-8">Nurture</p>
          <a href="https://trailblisstravel.co.ke/" target="blank" className="bg-custom-brown px-6 py-4 text-white rounded-lg">Visit Website</a>
        </div>
      </div>
      <MouseScroll />
    </div>
  );
}
