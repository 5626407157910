import React from 'react'
import Lottie from 'lottie-react';
import loadingSpinner from '../../lottie/loading_animation.json' // Import the JSON file

export default function LoadingSpinner() {
  return (
    <div className="counter">
    <Lottie
      animationData={loadingSpinner}
      loop={true}
      autoplay={true}
      speed={0.1}
      className='h-48 sm:h-20'
    />
  </div>
  )
}
