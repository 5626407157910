import React from "react";
import Lottie from "lottie-react";
import loadingText from "../../lottie/loading_text.json"; // Import the JSON file

export default function LoadingText() {
  return (
    <div>
      <Lottie
        animationData={loadingText}
        loop={true}
        autoplay={true}
        speed={0.1}
        style={{ height: "10%" }}
      />
    </div>
  );
}
