import { React, useState } from "react";
import TopNavigationBar from "../components/topNavBar";
import FilterButton from "../components/filterButton";
import ContactMe from "../sections/footer";
import ProjectListView from "../components/ProjectListView";
import ProjectCardView from "../components/ProjectCardView";
import PageTransition from "../components/transition";
import { CustomBackgroundMenu } from "../utility/CustomBackgroundMenu";
import { Helmet } from "react-helmet";

export default function Projects() {
  const projectData = [
    {
      id: 1,
      client: "FemflyTraveling Agency",
      services: "Design",
      year: "2023",
      imageUrl: "project-thumbnail/gmjhft1kf8wcpnhkie86",
      type: ["Design"],
      link: "work/caseStudy/femfly",
      projectDuration: 1
    },
    {
      id: 2,
      client: "OlolaiTours",
      services: "Design & Development",
      year: "2023",
      imageUrl: "project-thumbnail/yxnniwcvfuwfoscpwcm5",
      type: ["Design", "Development"],
      link: "work/caseStudy/ololaitours",
      projectDuration: 2
    },
    {
      id: 3,
      client: "AssetFix",
      services: "Design & Development",
      year: "2023",
      imageUrl: "project-thumbnail/dr9sbzphn0xobvc8m2te",
      type: ["Design", "Development"],
      link: "/work/caseStudy/assetfix",
      projectDuration: 1
    },
    {
      id: 4,
      client: "TrailBliss Travelling Agency",
      services: "Design & Development",
      year: "2023",
      imageUrl: "project-thumbnail/ke2nhqmrlauiphte9mu2",
      type: ["Design", "Development"],
      link: "/work/caseStudy/trailbliss",
      projectDuration: 1
    },
    {
      id: 5,
      client: "TBlog",
      services: "Design",
      year: "2022",
      imageUrl: "project-thumbnail/zzyldpqspkdo2udw83yc",
      type: ["Design"],
      link: "work/caseStudy/tblog",
      projectDuration: 1
    },
  ];

  // Count the number of projects in each category
  const designCount = projectData.filter((project) =>
    project.type.includes("Design")
  ).length;
  const developmentCount = projectData.filter((project) =>
    project.type.includes("Development")
  ).length;

  const [viewType, setViewType] = useState("list");
  const [activeFilter, setActiveFilter] = useState("All");

  const switchToListView = () => {
    setViewType("list");
  };

  const switchToCardView = () => {
    setViewType("card");
  };

  const filterProjects = (type) => {
    setActiveFilter(type);
  };

  const filteredProjects =
    activeFilter === "All"
      ? projectData
      : projectData.filter((project) => project.type.includes(activeFilter));

  const customColor = CustomBackgroundMenu(window.location.pathname);
  return (
    <PageTransition>
      <Helmet>
        <title>Stephen Muchara - MyWork</title>
      </Helmet>
      <TopNavigationBar backgroundColor={customColor} />
      <div className="mx-10 sm:mx-1 sm:my-0 tablet:mx-4 tablet:my-4">
        <div className="py-4 mx-4">
          <p className="text-5xl sm:text-3xl my-2">Crafting tomorrow's</p>
          <p className="text-5xl sm:text-3xl">digital solutions</p>
        </div>
        <div className="flex sm:mr-2 sm:flex-row justify-between items-center sm:grid">
          <div className="flex sm:order-2">
            <FilterButton
              buttonName={`All`}
              onClick={() => filterProjects("All")}
              isActive={activeFilter === "All"}
              count={projectData.length}
            />
            <FilterButton
              buttonName={`Design`}
              onClick={() => filterProjects("Design")}
              isActive={activeFilter === "Design"}
              count={designCount}
            />
            <FilterButton
              buttonName={`Development`}
              onClick={() => filterProjects("Development")}
              isActive={activeFilter === "Development"}
              count={developmentCount}
            />
          </div>
          <div className="flex sm:order-1 sm:justify-end sm:my-4">
            <div
              className="cursor-pointer w-14 h-14 sm:w-10 sm:h-10 rounded-full border border-black flex justify-center items-center mx-1"
              onClick={switchToListView}
            >
              <box-icon name="list-ul"></box-icon>
            </div>
            <div
              className="cursor-pointer w-14 h-14 sm:w-10 sm:h-10 rounded-full border border-black flex justify-center items-center mx-1"
              onClick={switchToCardView}
            >
              <box-icon name="card"></box-icon>
            </div>
          </div>
        </div>

        <div>
          {viewType === "list" ? (
            <div>
              <div className="grid grid-cols-4 mx-4 my-10">
                <p className="text-xs text-gray-600">CLIENT</p>
                <p className="text-xs text-gray-600">SERVICES</p>
                <p className="text-xs text-gray-600">YEAR</p>
                <p className="text-xs text-gray-600">PROJECT DURATION (Months)</p>
              </div>
              {filteredProjects.map((project) => (
                <ProjectListView key={project.id} projectData={project} />
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-2 sm:grid-cols-1">
              {filteredProjects.map((project) => (
                <ProjectCardView key={project.id} projectData={project} />
              ))}
            </div>
          )}
        </div>
      </div>
      <ContactMe />
    </PageTransition>
  );
}
