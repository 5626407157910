import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import Notfound from "./sections/notFound";
import FemFly from "./caseStudy/femfly";
import { AnimatePresence } from "framer-motion";
import Ololaitours from "./caseStudy/ololaitours";
import 'boxicons';
import "./App.css";
import Contact from "./pages/contact";
import Projects from "./pages/projects";
import About from "./pages/about";
import Trailbliss from "./caseStudy/trailbliss";
import AssetFix from "./caseStudy/assetfix";

function App() {
  return (
    <Router>
      <div className="grid font-poppins relative">
        <AnimatePresence mode="wait" initial={false}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/caseStudy/femfly" component={FemFly} />
              <Route path="/work/caseStudy/femfly" component={FemFly} />
              <Route path="/caseStudy/ololaitours" component={Ololaitours} />
              <Route path="/work/caseStudy/ololaitours" component={Ololaitours} />
              <Route path="/caseStudy/trailbliss" component={Trailbliss} />
              <Route path="/work/caseStudy/trailbliss" component={Trailbliss} />
              <Route path="/caseStudy/assetfix" component={AssetFix} />
              <Route path="/work/caseStudy/assetfix" component={AssetFix} />
              <Route path="/work" component={Projects} />
              <Route path="/contact" component={Contact} />
              <Route path="/about" component={About} />
              <Route path="*" exact component={Notfound} />
            </Switch>
        </AnimatePresence>
      </div>
    </Router>
  );
}

export default App;
