import React from "react";
import { Link } from "react-router-dom";
import MouseScroll from "../components/mouse";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

export default function Content() {
  // Set the Cloud configuration and URL configuration
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dfpasf355",
    },
    url: {
      secure: true,
    },
  });

  // cld.image returns a CloudinaryImage with the configuration set.
  const heroCourasel = cld.image("project-thumbnail/wjolr5y5n1hafor4dpxn");
  return (
    <div className="px-16 sm:px-4 sm:pt-8 tablet:px-6">
      <div className="grid gap-6 grid-cols-5 content-center items-center mb-5 sm:grid-cols-1">
        <div className="col-span-2">
          <h1 className="text-5xl sm:text-3xl font-bold tracking-widest">
            Building Digital Products, Brands & Experience
          </h1>
          <p className="mt-4 mb-8">
            What if your online presence could be the key to unlocking a world
            of endless possibilities for your business? Did you know that
            businesses with a strong online presence are 75% more likely to
            attract and retain customers? Let's make those statistics work for
            you.
          </p>
          <Link
            to="/contact"
            className="py-4 px-6 bg-dark-gray text-white rounded-lg w-1/3 sm:w-1/2 tablet:w-1/2"
          >
            Let's Start a Project.
          </Link>
        </div>
        <div className="flex justify-center items-center col-span-3 sm:mt-14">
          <AdvancedImage
            cldImg={heroCourasel}
            plugins={[placeholder("blur")]}
            alt="Stephen Muchara Web Services Description Image"
          />
        </div>
      </div>
      <MouseScroll />
    </div>
  );
}
