import React from "react";
import { Link } from 'react-router-dom';

export default function ProjectListView({ projectData }) {
  return (
    <div>
      <Link to={projectData.link}>
      <div className="grid grid-cols-4 mx-4 py-8 border border-t-1 border-b-0 border-x-0 text-sm sm:text-xs">
        <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
          {projectData.client}
        </p>
        <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
          {projectData.services}
        </p>
        <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
          {projectData.year}
        </p>
        <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
          {projectData.projectDuration}
        </p>
      </div>
      </Link>
    </div>
  );
}
