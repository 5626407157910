import React from "react";
import PageTransition from "../components/transition";
import ContactMe from "../sections/footer";
import OlolaiHeroSection from "../sections/ololaitours/OlolaiHeroSection";
import OlolaiProjectCaseStudy from "../sections/ololaitours/OlolaiProjectCaseStudy";
import OlolaiProjectDesign from "../sections/ololaitours/OlolaiProjectDesign";
import OlolaiEmpathyGraph from "../sections/ololaitours/OlolaiEmpathyGraph";
import OlolaiDesignProcess from "../sections/ololaitours/OlolaiDesignProcess";
import { Helmet } from "react-helmet";


export default function Ololaitours() {
  return (
    <PageTransition>
      <Helmet>
        <title>Stephen Muchara - Ololai Tours Case Study</title>
      </Helmet>
      <OlolaiHeroSection />
      <div className="px-16 sm:px-4">
        <OlolaiProjectCaseStudy />
      </div>
      <OlolaiProjectDesign />
      <div className="px-16 sm:px-4">
        <OlolaiDesignProcess />
        <OlolaiEmpathyGraph/>
      </div>
      <ContactMe />
    </PageTransition>
  );
}
