import {Link} from 'react-router-dom'
import TopNavigationBar from '../components/topNavBar';
import Error404 from '../assets/images/error404.jpg'

const Notfound = () => {
  return (
    <div>
      <TopNavigationBar/>
      <div className="flex flex-col justify-center items-center">
      <img src={Error404} alt="" className='h-[70vh]' />
      <Link to={'/'} className="bg-dark-gray text-white px-6 py-4 rounded-md shadow-lg">Go Back To Homepage</Link>
      </div>
    </div>
  );
};

export default Notfound;
