import React from "react";
import TopNavigationBar from "../components/topNavBar";
import PageTransition from "../components/transition";
import ContactMe from "../sections/footer";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <PageTransition>
      <Helmet>
        <title>Stephen Muchara - About</title>
      </Helmet>
      <TopNavigationBar />
      <div className="mx-24 my-12 p-8 sm:mx-0 sm:my-0 tablet:mx-4 tablet:my-4">
        <p className="text-5xl sm:text-xl font-bold">Turning Concepts into</p>
        <p className="text-5xl sm:text-xl font-bold">
          Compelling Digital Realities
        </p>
        <div className="grid grid-cols-2 my-8 sm:grid-cols-1">
          <p>
            I'm on a mission to collaborate with global businesses and deliver
            customized solutions that transcend expectations. Each project is an
            opportunity to redefine the status quo, with an unwavering
            commitment to excellence at its core.
          </p>
        </div>
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-1">
          <div>
            <h1 className="text-xl my-4">Design</h1>
            <hr className="my-8" />
            <p>
              With a proven history of crafting impactful digital experiences
              through web and app design, I specialize in creating robust,
              user-centric digital solutions. I firmly believe that a strong
              company's identity is the cornerstone of every successful online
              venture.
            </p>
          </div>
          <div>
            <h1 className="text-xl my-4">Development</h1>
            <hr className="my-8" />
            <p>
              I specialize in building scalable websites from scratch that
              seamlessly blend with design. My primary focus is on crafting
              micro animations, seamless transitions, and captivating user
              interactions. As for content management, I'm all about building
              tailored solutions that perfectly suit your project, ensuring your
              website functions flawlessly and looks stunning.
            </p>
          </div>
          <div>
            <h1 className="text-xl my-4">Design & Development</h1>
            <hr className="my-8" />
            <p>
              I'm a versatile creative professional with a passion for both
              design and development. My journey involves crafting scalable
              websites from the ground up that seamlessly integrate design,
              while paying meticulous attention to micro animations, smooth
              transitions, and engaging user interactions. I'm not just about
              aesthetics; I'm dedicated to ensuring the functionality is as
              outstanding as the visual appeal. Let's embark on a collaborative
              journey to create digital experiences that are both visually
              striking and technically sound.
            </p>
          </div>
        </div>
      </div>
      <ContactMe />
    </PageTransition>
  );
}
