// Projects.js
import ProjectCards from "../components/projectCards";

const Projects = () => {
  return (
    <div className="p-14 px-24 sm:px-5 tablet:px-5 sm:mt-0 tablet:mt-0">
      <p className="text-lg my-2 text-gray-500">Latest Projects</p>
      <h1 className="text-2xl font-bold">Project Showcase</h1>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 my-4">
        <ProjectCards />
      </div>
    </div>
  );
};

export default Projects;
