import Projects from "../sections/project";
import ContactMe from "../sections/footer";
import About from "../sections/about";
import Affiliate from "../sections/Affiliate";
import HeroSection from "../sections/heroSection";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Stephen Muchara - HomePage</title>
      </Helmet>
      <HeroSection />
      <About />
      <Affiliate />
      <Projects />
      <ContactMe />
    </div>
  );
};

export default Home;
