import { motion, useAnimation } from "framer-motion";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { URLConfig } from "@cloudinary/url-gen";
import { CloudConfig } from "@cloudinary/url-gen";
import { useState } from "react";
import { Link } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [progress, setProgress] = useState(0);
  const widgetControls = useAnimation();

  const cardVariants = {
    hover: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      cursor: "pointer",
    },
    initial: {
      backgroundColor: "transparent",
    },
  };

  const textVariants = {
    hover: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    initial: {
      opacity: 0,
      y: 20,
    },
  };

  const widgetVariants = {
    hover: {
      rotate: 360,
    },
    initial: {
      rotate: 0,
    },
  };

  const loadingVariants = {
    loading: {
      strokeDasharray: `${progress} 87.96`,
      color: "white",
    },
    initial: {
      strokeDasharray: "0 87.96",
      color: "black",
    },
  };

  const handleHover = async () => {
    if (window.innerWidth >= 640) {
      setIsHovered(true);
      await widgetControls.start("hover");
      // Simulate loading progress from 0 to 100
      for (let i = 0; i <= 100; i += 5) {
        setProgress(i);
        await new Promise((resolve) => setTimeout(resolve, 0)); // Adjust the delay for smoother animation
      }
    }
  };

  const handleUnhover = async () => {
    if (window.innerWidth >= 640) {
      setIsHovered(false);
      await widgetControls.start("initial");
      setProgress(0); // Reset progress when unhovered
    }
  };
  // Set the Cloud configuration and URL configuration
  const cloudConfig = new CloudConfig({ cloudName: "dfpasf355" });
  const urlConfig = new URLConfig({ secure: true });

  return (
    <Link to={project.link}>
      <motion.div
        className="relative shadow-xl w-full h-[570px] tablet:h-[400px] sm:h-[400px] flex flex-col items-center justify-center"
        variants={cardVariants}
        initial="initial"
        whileHover={window.innerWidth >= 640 ? "hover" : ""}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
      >
        <div className="absolute top-0 left-0 p-6">
          <h1 className="text-sm">{project.duration}</h1>
          <p className="font-bold text-lg sm:text-xs">{project.agency}</p>
          <p className="text-sm sm:text-xs">View Case Study</p>
        </div>
        <div className="absolute top-0 left-0 p-6">
          <motion.h1
            variants={textVariants}
            className={`text-sm sm:hidden ${isHovered ? "text-white" : ""}`}
          >
            <h1 className="text-sm sm:hidden">{project.duration}</h1>
          </motion.h1>
          <motion.p
            className={`font-bold text-lg sm:text-sm sm:hidden ${
              isHovered ? "text-white" : ""
            }`}
            variants={textVariants}
          >
            {project.agency}
          </motion.p>
          <div className="flex items-center">
            <motion.p
              variants={textVariants}
              className={`text-sm sm:hidden ${isHovered ? "text-white" : ""}`}
            >
              View Case Study
            </motion.p>
          </div>
        </div>
        <div
          className="relative w-full h-full bg-transparent transition-colors duration-100"
          style={{
            zIndex: -1,
          }}
        >
          <AdvancedImage
            cldImg={
              new CloudinaryImage(project.imageUrl, cloudConfig, urlConfig)
            }
            plugins={[placeholder("blur")]}
            className="object-cover w-full h-full rounded-lg shadow-md"
            alt={`Stephen Muchara - ${project.agency} Project`}
          />
        </div>
        {isHovered && (
          <motion.div
            className="absolute top-0 right-0 p-4 z-50"
            variants={widgetVariants}
            animate={widgetControls}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              stroke="currentColor"
              fill="none"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {/* Circle path */}
              <motion.circle
                cx="16"
                cy="16"
                r="14"
                strokeDasharray="0 87.96"
                variants={loadingVariants}
                initial="initial"
                animate={isHovered ? "loading" : "initial"}
                transition={{ duration: 1, ease: "linear" }}
              />
            </svg>
          </motion.div>
        )}
      </motion.div>
    </Link>
  );
};

const ProjectCards = () => {
  const projects = [
    {
      id: 1,
      duration: "Sep 2023 - Sep 2023",
      agency: "FemFly Travelling Agency",
      header: "Explore the World with Femfly Travel Agency",
      description:
        "Femfly Travel Agency is your trusted partner for unlocking extraordinary global experiences. From fulfilling travel dreams to seamless visa processes and settling in new destinations, we're here to guide you every step of the way. Explore the world with confidence through Femfly Travel Agency.",
      link: "/caseStudy/femfly",
      imageUrl: "project-thumbnail/gmjhft1kf8wcpnhkie86",
    },
    {
      id: 2,
      duration: "Aug 2023 - Aug 2023",
      agency: "Ololai Tours",
      header: "Ololai Tours - Your Gateway to Extraordinary Journeys",
      description:
        "Ololai Tours specializes in crafting unforgettable travel experiences to the world's most captivating destinations. Whether you're in search of hidden gems, cultural immersion, or luxurious getaways, our expert team is dedicated to making your travel dreams come true. Join us for an extraordinary journey beyond imagination.",
      link: "/caseStudy/ololaitours",
      imageUrl: "project-thumbnail/yxnniwcvfuwfoscpwcm5",
    },
    {
      id: 3,
      duration: "March 2023 - March 2023",
      agency: "Identigate Integrated Solutions",
      header: "AssetFix",
      description:
        "Connecting with nature fosters a profound sense of peace and oneness with the universe, providing solace from modern life's demands. Immersing ourselves in natural settings like forests or the night sky prompts contemplation of the universe's mysteries, leading to a spiritual bond with the world around us.",
      link: "/caseStudy/assetfix",
      imageUrl: "project-thumbnail/dr9sbzphn0xobvc8m2te",
    },
    {
      id: 5,
      duration: "May 2023 - May 2023",
      agency: "TrailBliss Travelling Agency",
      header: "TrailBliss Travelling Agency",
      description:
        "Connecting with nature fosters a profound sense of peace and oneness with the universe, providing solace from modern life's demands. Immersing ourselves in natural settings like forests or the night sky prompts contemplation of the universe's mysteries, leading to a spiritual bond with the world around us.",
      link: "/caseStudy/trailbliss",
      imageUrl: "project-thumbnail/ke2nhqmrlauiphte9mu2",
    },
  ];
  return (
    <>
      {projects.map((project) => (
        <div key={project.id}>
          <ProjectCard project={project} />
        </div>
      ))}
    </>
  );
};

export default ProjectCards;
