import React from "react";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";


const cardData = [
  {
    title: "Says",
    content: [
      "I want Real Photo's of Hotels To be on the Website",
      "I don't want to have to follow many steps in order to successfully book a package",
      "Packages must be up to date no need of making an",
    ],
  },
  {
    title: "Thinks",
    content: [
      "Will I lose my money after I've booked a package ?",
      "Maybe Some money is used for Fraud purposes ?",
      "When is it a good season for booking a package ?",
    ],
  },
  {
    title: "Does",
    content: [
      "I love to travel at least once a month to get and experience new culture",
      "I'm accustomed to booking packages majorly weekend getaways cause they are work-friendly",
      "I mostly prefer office booking not online transactions to avoid being scammed",
    ],
  },
  {
    title: "Feels",
    content: [
      "I feel uncomfortable when booking is only online, no physical offices",
      "I hate planning on a package only to discover it is long overdue the website is not up to date",
      "I'm afraid of trying a new agency. Usually overwhelmed by the thought of being scammed",
    ],
  },
];

export default function OlolaiEmpathyGraph() {
   // Set the Cloud configuration and URL configuration
   const cld = new Cloudinary({
    cloud: {
      cloudName: "dfpasf355",
    },
    url: {
      secure: true,
    },
  });

  // cld.image returns a CloudinaryImage with the configuration set.
  const user = cld.image("case-study/ololai-tours/gtw2l8qhdivwsuknc5b1");

  return (
    <div className="my-16">
      <h1 className='text-4xl sm:text-2xl font-bold pb-16'>Empathy Map</h1>
      <div className="relative flex justify-center items-center">
      <AdvancedImage
          cldImg={user}
          plugins={[placeholder("blur")]}
          className="absolute w-40"
        />
        <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="bg-custom-orange p-6 shadow-lg rounded-lg h-72 sm:text-sm"
            >
              <p
                className={`font-bold text-2xl sm:text-base my-4 ${
                  card.title === "Thinks" || card.title === "Feels"
                    ? "text-right sm:text-left"
                    : ""
                }`}
              >
                {card.title}
              </p>

              <ol className="list-disc mx-5">
                {card.content.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
