import React, { useState, useEffect } from "react";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { CloudConfig } from "@cloudinary/url-gen";

export default function Affiliate() {
  // Set the Cloud configuration
  const cloudConfig = new CloudConfig({ cloudName: "dfpasf355" });
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex + 1) %
          [
            "company-logos/bfawhtiddy6vcm5gczkd",
            "company-logos/u2moa71k7zxe6wamclil",
            "company-logos/qy1bvgpnauzosooncvat",
            "company-logos/isixjqjftxnqyjkmf2xx",
            "company-logos/xuhd9t0kuwvdhjsscxpk",
          ].length
      );
    }, 3000); // Change logo every 3 seconds

    return () => {
      clearInterval(interval);
    };
  });

  const companyNames = [
    "Identigate Integrated Solutions",
    "OlolaiTours",
    "Pilgrim Consulting",
    "AssetFix (Identigate Integrated Solutions)",
    "TrailBliss Travelling Agency",
  ];

  const developerName = "Stephen Muchara";

  return (
    <div className="mt-20 px-16">
      <h1 className="text-center font-bold text-3xl">
        Companies That I've built Solutions For
      </h1>
      <div className="py-8 flex tablet:gap-10 sm:gap-6 flex-row gap-24 justify-center items-center w-full">
        {[
          "company-logos/bfawhtiddy6vcm5gczkd",
          "company-logos/u2moa71k7zxe6wamclil",
          "company-logos/qy1bvgpnauzosooncvat",
          "company-logos/isixjqjftxnqyjkmf2xx",
          "company-logos/xuhd9t0kuwvdhjsscxpk",
        ].map((logo, index) => (
          <AdvancedImage
            key={index}
            cldImg={new CloudinaryImage(logo, cloudConfig)}
            plugins={[placeholder("blur")]}
            loading="lazy"
            className="w-32 sm:w-12"
            style={{
              filter:
                currentIndex === index ? "grayscale(0%)" : "grayscale(100%)",
              transform: `scale(${currentIndex === index ? 1.5 : 1})`,
              transition: "transform 0.3s, filter 0.3s", // Adjust the duration as needed
            }}
            alt={`Worked as a developer for ${companyNames[index]} - ${developerName}`}
          />
        ))}
      </div>
    </div>
  );
}
