import React from "react";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import TopNavigationBar from "../../components/topNavBar";

export default function OlolaiHeroSection() {
  // Set the Cloud configuration and URL configuration
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dfpasf355",
    },
    url: {
      secure: true,
    },
  });

  // cld.image returns a CloudinaryImage with the configuration set.
  const ololaiLogo = cld.image("company-logos/it4ys3wy5qy2drajl988");
  const projectThubmnail = cld.image(
    "case-study/ololai-tours/uwwz1tuhnbmds57zwq5y"
  );
  return (
    <div className="h-[120vh] overflow-y-hidden w-screen sm:h-full bg-custom-orange">
      <TopNavigationBar />
      <div className="grid">
        <AdvancedImage
          cldImg={ololaiLogo}
          plugins={[placeholder("blur")]}
          className="w-60 sm:w-40 m-auto mb-4"
          alt="Ololai Tours Logo - Designed by Stephen Muchara"
        />

        <h1 className="text-center text-3xl sm:text-xl w-2/5 sm:w-4/5 m-auto">
          Unlocking Wanderlust: <span className="font-bold">Crafting</span>{" "}
          Unforgettable Travel Experiences with{" "}
          <span className="font-bold">Ololai Tours</span>
        </h1>
        <AdvancedImage
          cldImg={projectThubmnail}
          plugins={[placeholder("blur")]}
          className="-m-40 mx-auto w-full sm:w-full  sm:m-0"
          loading="lazy"
          alt="Stephen Muchara - Mobile Mockup Presentation of OlolaiTours Website"
        />
      </div>
    </div>
  );
}
