import React from "react";
import { Link } from "react-router-dom";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { CloudinaryImage } from "@cloudinary/url-gen";
import { URLConfig } from "@cloudinary/url-gen";
import { CloudConfig } from "@cloudinary/url-gen";

export default function ProjectCardView({ projectData }) {
  // Set the Cloud configuration and URL configuration
  let cloudConfig = new CloudConfig({ cloudName: "dfpasf355" });
  let urlConfig = new URLConfig({ secure: true });
  return (
    <Link to={projectData.link}>
      <div className="mx-4 my-20">
        <div className="relative shadow-md w-full h-[570px] tablet:h-[400px] sm:h-[400px]">
          <AdvancedImage
            cldImg={
              new CloudinaryImage(projectData.imageUrl, cloudConfig, urlConfig)
            }
            plugins={[placeholder("blur")]}
            className="object-cover w-full h-full rounded-lg shadow-md"
            alt={`Stephen Muchara - ${projectData.client} Project`}
          />
          <p className="text-3xl my-4">{projectData.client}</p>
          <hr className="my-4" />
          <div className="flex justify-between">
            <p className="text-sm">{projectData.services}</p>
            <p className="text-sm">{projectData.year}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}
